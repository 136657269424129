var $ = function () {

}
var $VTrigger = function () {

}
var Activity = { "InitActivity": function () {}}
var tmplpage = "tmpl-page";
var tmpltxt = "tmpl-txt";
var tmplhtml = "tmpl-html";
var tmplimg = "tmpl-img";
var currentobj = null;
var currborder = null;
var pages = [];
var urlgetdata = location.origin + "/MarketingModule/m2/data";
var urlsavedata = location.origin + "/MarketingModule/m2/save";

var getQueryString = function (a) {
    var url = window.location.search;
    // 正则筛选地址栏
    var reg = new RegExp("(^|&)" + a + "=([^&]*)(&|$)");
    // 匹配目标参数
    var result = url.substr(1).match(reg);
    //返回参数值
    return result ? decodeURIComponent(result[2]) : null;
}
var createScript = function (url) {
    var head = document.getElementsByTagName('body')[0];
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    head.appendChild(script);
}
var vt = 0;//浏览类型 //0开发,1查看，2编辑，3预览
var testmax = 100;//测试加载
var getMaterID = function () { //获取素材ID
    return getQueryString("mid");//|| "test001";
}
var initViewType = function () { //获取查看类型
    vt = getQueryString("vt") && parseInt(getQueryString("vt")) || 0;
}
var loadViewTrigger = function () { //加载触发器
    createScript("/js/vtrigger.js");
    var l = 0;
    (function loop() {
        ++l < testmax && (typeof ($VTrigger) != "undefined" && ($VTrigger().init(), 1) || setTimeout(loop, 100));
    })();
    return 1;
}
var loadLuck = function () { //加载红包
    createScript("/Scripts/Activity.js");
    var l = 0;
    (function loop() {
        ++l < testmax && (typeof (Activity) != "undefined" && (Activity.InitActivity(), 1) || setTimeout(loop, 100));
    })();
    return 1;
}



function Page(index, obj) {
    this.index = index;
    this.objPage = obj;
    this.tmpls = [];
    pages.push(this);
}
Page.prototype.discover = function () {
    var me = this;
    var tmplarray = [tmpltxt, tmplimg, tmplhtml];
    for (var i = 0; i < tmplarray.length; i++) {
        var tmpl = tmplarray[i];
        var objs = this.objPage.find("[" + tmpl + "]");
        if (objs && objs.length) {
            $.each(objs, function (i, oitem) {
                var obj = $(oitem);
                var name = obj.attr(tmpl);
                var val = $YJApp.database[me.index][name];
                var tmplitem = { "page": me.index, "tmpl": tmpl, "name": name, "val": val };
                console.log("tmplitem init discore:", tmplitem);
                obj.data("tmplitem", tmplitem);
                me.tmpls.push(tmplitem); //初始化结构数据
                obj.click(function () {    //注册事件                    
                    var objt = $(this);
                    var m = objt.data("tmplitem");
                    me.click(m, objt);
                });
            });
            console.log(this.index.toString() + "/" + name + " ok find");
        }
        else {
            console.log(this.index.toString() + "/" + name + " not find");
        }
    }

}
Page.prototype.click = function (tmplitem, objt) {
    if (parent.window && parent.window.$YJApp && parent.window.$YJApp.isregselect) {
        if (currentobj)
            currentobj.css("border", currborder);
        currentobj = objt;
        currborder = objt.css("border");
        objt.css("border", "1px dashed");

        tmplitem.val = tmplitem.val && tmplitem.val.replace(/^(\s|\\n)+|(\s|\\n)+$/g, '') || "";        
        parent.window.$YJApp.apimain(tmplitem);
    }
}
Page.prototype.refreshdata = function (tmplitem) {
    var j = -1;
    for (var i = 0; i < this.tmpls.length; i++) {
        if (this.tmpls[i].name == tmplitem.name) {
            j = i;
            break;
        }
    }
    if (j > -1) {
        for (var name in this.tmpls[j])
            this.tmpls[j][name] = tmplitem[name];
    }
}


var ops = function (op, ks) {
    var key = ks.toString();
    return op&& op[key] || null
}

var currenttmplitem = null;
var $YJApp = {
    "database": [],
    "iframe": null,
    "openchat": function () { //打开聊一聊

    },
    "getbizinfo": function () {

    },
    "refresh": function (callback) {//刷新数据       
        var me = this;
        me.database = me.database || [];
        var materid = getMaterID();
        var url = urlgetdata + "?MaterID=" + materid;
        $.get(url).done(function (data) {
            if (!data.Result) {
                alert(data.Message);
                return false;
            }
            var dbdata = data.Data;
            if (dbdata && dbdata.length) {
                var lendbui = me.database.length;
                for (var i = 0; i < dbdata.length; i++) {
                    var dbitem = dbdata[i];
                    dbitem.page < lendbui && (me.database[dbitem.page][dbitem.name] = dbitem.val, 1);
                }
            }
            callback && callback();
            return true;
        }).fail(function (e) {
            if (!(e.status == 0 && e.statusText == "error"))
                alert("服务器异常:" + e.responseText || '');
        });
        return 1;
    },
    "init": function (callback) { //初始化数据             
        var me = this;
        initViewType();//初始化浏览类型
        var mid = getMaterID();
        !mid && (vt == 1 || vt == 3) && ($(function () { $("body").html("<h1>页面参数错误，无法找到素材ID</h1>") }), 1) || $(function () {
            vt && me.initdatabase() && mid && me.refresh(function () { callback(mid, vt); vt == 1 && loadViewTrigger() && loadLuck(); }) || callback(mid, vt);
        });
    },
    "initdatabase": function () {
        var pagetmpls = $("div[" + tmplpage + "]");
        for (var j = 0; j < pagetmpls.length; j++) {
            var objpage = $(pagetmpls[j]);
            var table = {};
            var tmplarray = [tmpltxt, tmplimg, tmplhtml];
            for (var i = 0; i < tmplarray.length; i++) {
                var tmpl = tmplarray[i];
                var objs = objpage.find("[" + tmpl + "]");
                if (objs && objs.length) {
                    $.each(objs, function (i, oitem) {
                        var obj = $(oitem);
                        var name = obj.attr(tmpl);
                        table[name] = null;
                    });
                }
            }
            $YJApp.database.push(table);
        }
        console.log("init database", JSON.stringify(this.database));
        return 1;
    },
 
    "vuebind": function (v) {
        var me = this;
        if (v && v.length && vt) {
            for (var i = 0; i < me.database.length; i++) {
                for (var name in me.database[i])
                    ops(v[i], name) && me.database[i][name] && (v[i][name] = me.database[i][name], 1) || (me.database[i][name] = v[i][name], 1) //ops(v[i], name)!=null && (v[i][name] = me.database[i][name], 1);                     
            }
        }
        if (vt != 2)
            return 0;
        var pagetmpls = $("div[" + tmplpage + "]");
        for (var k = 0; k < pagetmpls.length; k++) {
            var page = new Page(k, $(pagetmpls[k]));
            page.discover();
        }
        console.log("pages:", pages);
        this.vues = v;
    },
    "publishtarget": null,
    "publishready": null,
    "isregselect": 0,
    "regselect": function (config) {
        console.log(config);
        this.publishtarget = config["onselect"];
        this.iframe = config["iframe"];
        this.isregselect = 1;
        config["hasOwnProperty"]("onready") && config["onready"] && (this.publishready = config["onready"], 1);
    },
    "apimain": function (tmplitem) {
        currenttmplitem = tmplitem;
        if ($YJApp && $YJApp.publishtarget)
            $YJApp.publishtarget(tmplitem);
    },
    "vues": [],
    "setingval": function (tmplitem) {
        var me = this;
        console.log("child item:", tmplitem);
        if (me.vues && me.vues.length) {
            var vt = me.vues[tmplitem.page];
            pages[tmplitem.page].refreshdata(tmplitem);
            ops(vt, tmplitem.name) && (vt[tmplitem.name] = tmplitem.val, 1);
        }
    },
    "update": function (val) { //父级调用
        var me = this;
        if (!me.iframe)
            return false;
        var child = currenttmplitem && (currenttmplitem["val"] = val, 1) && document.getElementById(me.iframe).contentWindow || null;
        child && child.$YJApp && child.$YJApp.setingval(currenttmplitem);
    },
    "apisave": function (argmid) {
        var materid = argmid;
        if (!materid)
            return 0;
        var pdata = [];
        console.log("save pages:", pages);
        for (var i = 0; i < pages.length; i++) {
            var tms = pages[i].tmpls;
            for (var j = 0; j < tms.length; j++)
                pdata.push(tms[j]);
        }
        $.post(urlsavedata, { "MaterID": materid, "Items": pdata }).done(function (data) {
            !data.Result && alert(data.Message);
        }).fail(function (e) {
            if (!(e.status == 0 && e.statusText == "error"))
                alert("服务器异常:" + e.responseText || '');
        });
    },
    "save": function (argmid) {
        if (!argmid) {
            console.log("缺少素材ID");
            return 0;
        }
        var child = this.iframe && document.getElementById(this.iframe).contentWindow || null;
        child && child.$YJApp && child.$YJApp.apisave(argmid);
        return 1;
    },
    "draw": function (fnc) {
        var me = this;
        if (!me.iframe)
            return false;
        var child = document.getElementById(me.iframe).contentWindow || null;
        child && child.$YJApp && child.$YJApp.apidraw(fnc);
    },
    "addtext": function () {
        return this.addtmpl(tmpltxt);
    },
    "addimg": function () {
        return this.addtmpl(tmplimg);
    },  
    "addtmpl": function (tm) {
        var child = this.iframe && document.getElementById(this.iframe).contentWindow || null;
        child && child.$YJApp && child.$YJApp.apiaddtmpl(tm);
        return 1;
    },
    "updatestyle": function (n, v) {
        var me = this;
        if (!me.iframe)
            return false;        
        var child = currenttmplitem && currenttmplitem["style"][n] != v && (currenttmplitem["style"][n] = v, 1) && document.getElementById(me.iframe).contentWindow || null;
        child && child.$YJApp && child.$YJApp.apiupdatestyle(currenttmplitem,n,v);
    },
    "destroy": function () {
        this.database = [];
        this.iframe = null;
        this.isregselect = 0;
        this.vues = [];
        currenttmplitem = null;
        currentobj = null;
        currborder = null;
        pages = [];
    }
};


export default { $YJApp}